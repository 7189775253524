import { render, staticRenderFns } from "./aboutus.vue?vue&type=template&id=c632f9ae&scoped=true&"
import script from "./aboutus.vue?vue&type=script&lang=js&"
export * from "./aboutus.vue?vue&type=script&lang=js&"
import style0 from "./aboutus.vue?vue&type=style&index=0&id=c632f9ae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c632f9ae",
  null
  
)

export default component.exports