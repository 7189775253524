<template>
  <div class="aboutus-cnt">
    <h6 class="h6">关于我们 - About us</h6>
    <div class="cnt">
      <p class="p">微微定网站，是由深圳市微微数码有限公司创立，是国内领先的商务印品一站式采购平台，打造品质创意的印刷美学，产品
涵盖企业宣传办公、展会、速印、餐饮5大类、多种印品、多种选择，您足不出户，即可在线完成订购，坐等送货上门。</p>
      <p class="p">微微定精选全国优质印刷厂商，发掘厂家单品优势，集中零散订单，降低企业印刷成本，提升印刷质量与效率，成功解决线
下印刷成本高、流程复杂、质量参差不齐、起印量受限等问题，为您提供高效、高质和高品位的印刷体验。微微定在产品上
追求极致，不断进行产品创新与升级，强调产品的创意与美感。微微定率先提供创意名片、奢华名片的印刷和设计服务，还
开发轻设计创意文具系列，深入了解现代人工作、生活需求，回归设计美感与人性化，颠覆过去一尘不变的文具样式，让学
习、办公更有趣和美观，赋予内涵和气质。</p>
      <p class="p">在设计方面，微微定为您提供多样化解决方案——图到在线自助设计、优享设计一对一服务，您可以使用图到选择优质
模板做出设计作品；还可以选择专业设计服务，有版式美化、原创设计、精品原创设计三种不同设计方案，满足您不同产品
和标准的设计需求。</p>
      <p class="p">微微定用心服务每一位客户，质量至上，承诺守信，不负期待！</p>
      <h6 class="h5">价值观：</h6>
      <ul class='ul'>
        <li>◇ 以用户为中心，一切为了用户的最佳体验</li>
        <li>◇ 锐意进取，不断的追求产品的创新和美感</li>
        <li>◇ 精益求精，细节成就完美</li>
        <li>◇ 多方共赢，充分整合多方印刷资源</li>
        <li>◇ 重信重诺，坚持品质至上，敢于担当</li>
      </ul>
      <h6 class="h5">企业愿景：</h6>
      <p class="p">微微定不断的优化产品和服务，成长为国内最大、最专业、体验最棒的在线印刷电商平台。</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'aboutus-cnt',
  props: {
    msg: String
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.aboutus-cnt {
  width: 946px;float:right;background: #fff;border-radius: 5px;
  .h6 {
    height: 68px;border-bottom:1px solid #f2f2f2;font-weight: normal;font-size: 22px;line-height: 68px;padding-left:20px;
  }
  .cnt {
    padding:15px;
    .p {
      font-size: 16px;color:#555555;line-height: 30px;margin-bottom:30px;
    }
    .h5 {
      font-weight:normal;font-size: 16px;color:#555;margin-bottom:10px;
    }
    .ul {
      margin-bottom:30px;
      li {
        font-size: 16px;color:#555;margin-bottom:10px;
      }
    }
  }
}
</style>
